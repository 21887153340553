// Hide search box
@media (min-width: 1025px) {
    .headerContent .header-search #siteSearch {
        display: none;
    }
}

// Useful mixin for before and after dividers
@mixin beforeafter {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

// Add line under header on pages without a banner
body.page-no-banner .pageHeader {
    border-bottom: 1px solid rgba(black, 0.1);
}

// Add divider to carousel
.carouselSlide {
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "homeintroangle.svg");
        background-size: 101%;
    }
}
body:not(.homepage) .carouselSlide {
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        bottom: -1px;
        background-image: url($assets-path + "homeintroangle-white.svg");
        background-size: 101%;
    }
}

// Add divider to home features
.homeIntroWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        bottom: calc(((-78 / 1600) * 100vw) + 1px);
        background-image: url($assets-path + "homefeaturesangle.svg");
        background-size: 101%;
    }
}

// Add divider to impact stats
.homefeaturecategory-homeboximpactstats {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: calc(((-78 / 1600) * 100vw) + 1px);
        background-image: url($assets-path + "impactangle.svg");
        background-size: 101%;
    }
}

// Overlap home features with home intro
.homeIntroWrapper {
    padding-bottom: $spacer * 6;
    @media (max-width: map-get($breakpoints, md)) {
        padding-bottom: $spacer * 3;
    }

}
.homeFeaturesWrapper {
    margin-top: -$spacer * 7;
}

// Add divider and make colour changes
.homeFeeds .homeFeed:nth-child(2) {
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: calc(((-78 / 1600) * 100vw) + 1px);
        background-image: url($assets-path + "feedangle.svg");
        background-size: 101%;
    }
    background-color: $_purple;
    .feedsTitle {
        color: white;
    }
}

// Add divider to footer
.pageFooterWrapper {
    position: relative;
    &:after {
        @include beforeafter;
        height: calc((78 / 1600) * 100vw);
        top: calc(((-78 / 1600) * 100vw) + 1px);
        background-image: url($assets-path + "impactangle.svg");
        background-size: 101%;
    }
}

// menu button
body:not(.page-no-banner) .menuMainAlt {
    border-color: white;
    color: white;
    &:hover {
        background-color: white;
        border-color: white;
        color: $_grey;
        }
}

// Adjust slide detail padding for angle divider on mobile
@media (max-width: map-get($breakpoints, lg)) {
    .carouselSlideDetail {
        padding-bottom: $spacer * 3;
    }
}
// Footer stuff
.footerBox.footerBox2 {
    a {
        text-decoration: none;
        font-weight: bold;
    }
}
.footerBox.footerBox4 {
    border-top: 1px solid rgba(white, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        margin: 1rem;
        width: 200px;
        height: auto;
        &:first-child {
            margin-left: 0;
        }
    }
    @media (max-width: map-get($breakpoints, md)) {
        display: block;
        text-align: center;
    }
}

// CHange position of arrows for carousel on mobile because of clash with absolute header
@media (max-width: map-get($breakpoints, md)) {
    .carouselControls {
        align-items: flex-end;
    }
}

// Animated buttons in header
/* Bounce In */
@media (min-width: map-get($breakpoints, lg)) {
    .mainCallToAction {
        .cta-button {
            display: inline-block;
            vertical-align: middle;
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            transition-duration: 0.35s;
        }
        .cta-button:hover, .cta-button:focus, .cta-button:active {
            transform: scale(1.3);
            transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
            background-color: $donate-colour;
            color: white;
            border-color: $donate-colour;
        }
    }
}

@media (min-width: map-get($breakpoints, lg)) {
    .carouselControls {
        li a {
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            transition-duration: 0.35s;
            &:hover {
                transform: scale(1.3);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
            }
        }
    }
}

@media (min-width: map-get($breakpoints, lg)) {
    .searchContainer button,
    .socialIcons li a {
        &:before {
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            transition-duration: 0.35ss;
        }
        &:hover:before {
            transform: scale(1.3);
            transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        }
    }
}
// Adjust position of video play button on mobile
.carousel.hasCaption .carouselSlideTitle.showsVideo {
    &:before {
        @media (max-width: map-get($breakpoints, md)) {
            transform: translateY(75%);
        }
    }
    &:hover:before {
        color: white;
        @media (max-width: map-get($breakpoints, md)) {
            transform: translateY(75%);
        }
    }
}
// disable phone number styling for charity number on mobile
.charityNumber { pointer-events: none; }
.charityNumber > a { text-decoration:none; color:inherit; }

// Code for regular payment stuff in quick giving
.quickGivingPanel .selectDonationAmount>span:not(:first-child),
.quickGivingPanel .selectDonationAmount>a[href*=repeat-payment],
.quickGivingPanel .regularPayment {
    display: inline-block;
    vertical-align: middle;
}

.quickGivingPanel .regularPayment {
    margin-right: 1rem;
    margin-left: .5rem;
}

.quickGivingPanel .regularPaymentLabel {
    border-left: 1px solid white;
    padding-left: 1rem;
    @media (max-width: map-get($breakpoints, md)) {
        border-left: 0;
    }
}

// Add intro text paragraph and align stats to top
.homeImpactWrapper {
    h2 + p {
        text-align: center;
    }
    table tbody {
        align-items: flex-start;
    }
}

.formQuestion.donationAmountOther .donationAmountFigure {
    white-space: nowrap;
}

@media (max-width: $nav-breakpoint) {
    #menuMain {
        display: flex;
        flex-direction: column;
        .topLevel {
            width: 100%;
        }
        .mainCallToAction {
            order: -1;
        }
    }
    
}