// font-family: 'Carter One', cursive;
// font-family: 'Nunito Sans', sans-serif;

//
// Config values
//

$platform-name: 'romseymilltrust';


//
// Colours
//

$_orange: #ff6633;
$_green: #999933;
$_brown: #af8c70;
$_blue: #4a83ad;
$_purple: #70466c;
$_grey: #3d3e3e;

$brand-primary: $_grey;
$brand-secondary: $_orange;

$donate-colour: $brand-secondary !default;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo.svg';
$logo-width: 210px;
$logo-height: 100px;
$logo-absolute: true; // true if we want the logo to vertically intersect with the menu


//
// Typography
//

$font-family-base: 'Nunito Sans', sans-serif;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-font-family: 'Montserrat', sans-serif;
$headings-margin-top: 1.5em !default; // This will only affect headings within post content
$headings-font-weight: 800;


// Links
$link-colour: $_blue;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em;
$btn-padding-y: .6em;
$btn-font-weight: $font-weight-bold;
$btn-border-radius: 3px;
$btn-border-width: 3px;
$btn-border-colour: $_grey;
$btn-background-colour: transparent;
$btn-text-colour: $_grey;
$btn-font-family: $headings-font-family;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: white;

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific
$social-icons-header-colour: white; // A colour, or 'brand'

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-colour: $_grey;
$card-heading-font-size: $font-size-h4;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7;
$card-hover-image-scale: 1.1;

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-margin-y: 0;
$event-card-floating-date-margin-x: 0;

//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//
$header-absolute: true; // true if you want the header to appear transparently over banner images
$header-absolute-background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.8) 0%,
  rgba(0, 0, 0, 0.5) 60%,
  rgba(0, 0, 0, 0) 100%
  ); // Colour or gradient
  $header-absolute-breakpoint: 0; // When do you want to abandon the absolute header and return to solid?


// Header content
$header-content-margin-top: $spacer;
$header-content-margin-bottom: 0;
$header-content-padding-bottom: 2rem;

// Tagline

// Main call to action
$cta-btn-colour: white;

// Search

// Search - input

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: white;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: transparent;


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: $grey-dark;
$nav-top-level-item-hover-background-colour: white;
$nav-top-level-item-colour: white;
$nav-top-level-item-padding: 10px 15px;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);

// Burger button
$burger-btn-text-colour: white;

// Nav normal
// $nav-normal-margin-bottom: 0;
$nav-normal-align-items: right; // left, center, right
$nav-normal-margin-top: -$spacer * 1.5;
$nav-normal-margin-bottom: $spacer * 2;


//
// Specifically when no banner present and you have gone for $header-absolute: true
//

$no-banner-logo: 'logo-black.svg';
$no-banner-social-icons-header-colour: black; // A colour, or 'brand'
$no-banner-nav-top-level-item-colour: black;
$no-banner-header-search-button-icon-colour: black;


//
// Carousel
//
$carousel-max-width: 100%;
$carousel-image-overlay: linear-gradient(69.67deg, rgba(0, 0, 0, 0.5) 23.21%, rgba(0, 0, 0, 0.3) 46.48%, rgba(0, 0, 0, 0) 64.28%); // Add a colour value or gradient here
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-background-colour: transparent;
$carousel-details-padding: 0;
$carousel-heading-colour: white;
$carousel-heading-font-size: 3em;
$carousel-summary-colour: white;
$carousel-details-margin-y: $spacer * 4;
$carousel-details-max-width: 500px;
$carousel-read-more-border-colour: white;
$carousel-read-more-colour: white;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-icon-font-size: 2em;
$carousel-controls-icon-colour: white;
$carousel-controls-margin-y: 1rem;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-margin-top: 0;
$home-intro-background-colour: $_blue;
$home-intro-padding-y: $spacer * 3;
$home-intro-font-size: $font-size-h2;
$home-intro-content-max-width: $post-content-width;



//
// Home features
//
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-intro-colour: white;
$home-features-background-colour: transparent;



//
// Impact stats
//
$impact-stats-padding-y: $spacer * 4;


// Heading
$impact-stats-heading-margin-bottom: $spacer * 1;

// Individual stat

// Figure

// Summary


//
// Home feeds
//
$home-feeds-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-feeds-padding-top: $spacer * 3;
$home-feeds-padding-bottom: $spacer * 3;


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: $_grey;
$footer-boxes-count: 4;
$footer-prefab: 4;
$footer-link-colour: white;

// Footer admin links

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-colour: white;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-heading-text-align: left; // left, center, right
$newsletter-button-border-colour: white;
$newsletter-button-background-colour: transparent;
$newsletter-button-colour: white;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 2;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
$sidebar-layout: adjacent; // adjacent, below or above - where do you want the sidebar to be positioned on posts?

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//
$donation-form-banner-enabled: false;
$donation-form-frequency-tabs: true; // Have you added the DonationTarrifsAndTabs.js custom layout?


// Donation amounts


//
// Quick giving panel
//



//
// Home quick giving panel
//
$home-quick-giving-background-colour: $donate-colour;
$home-quick-giving-max-width: $container-max-width;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-padding-y: $spacer * 1;
$home-quick-giving-margin-top: -$spacer * 2;
$home-quick-giving-donate-btn-colour: white;
$home-quick-giving-donate-btn-border-colour: white;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
